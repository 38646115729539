import { ApiResponseData, PaymentOrderList, PurchaseOrder } from '/~/types/api'
import api from '/~/core/api'
import emitter from '/~/core/emitter'
import { cardCode } from '/~/utils/cards'
import { formatDollar } from '/~/utils/format/money'
import { formatNumber, roundFigure } from '/~/utils/format/numeric'
import { useCart } from '/~/composables/cart'
import { FlowType } from '/~/composables/checkout/checkout-types'
import Checkout from '/~/composables/checkout/core/Checkout'
import { useCms } from '/~/composables/cms'
import { useEwallet } from '/~/composables/ewallet'
import { useLocalization } from '/~/composables/localization'
import { usePaymentMethods } from '/~/composables/payment-methods'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import { usePoints } from '/~/composables/points'
import { useProvider } from '/~/composables/provider'

const {
  cartTotal,
  cartItemsFiltered,
  cartHasPhysical,
  cartHasEstore,
  cartHasDelivery,
  cartShippingFee,
  clearCart,
  resetCardOrderAddresses,
} = useCart()
const {
  burnPointsRateOrder,
  isPurchaseOrderPoints,
  pointsBalance,
  calculatePointsEarnedForPurchase,
} = usePoints()
const { ewalletBalance } = useEwallet()
const { isMethodAvailable } = usePaymentMethods()
const { translate } = useLocalization()

const ERROR_PAYMENT_CANCELLED = 'Payment cancelled'

interface CheckoutPayByAccountResponse {
  number: string
  user: string
  paymentLinkUrl: {
    amount: string
    callbackUrlOnFailure: string
    callbackUrlOnSuccess: string
    currency: {
      code: string
      name: string
    }
    expiresAt: string
    externalId: string
    transaction: null
    url: string
    createdAt: string
    id: string
    updatedAt: string
  }
}

export class PurchaseCheckout extends Checkout {
  flowType = FlowType.purchase
  cardVerificationType = 'default' as const
  isSchedulingAllowed = false

  constructor() {
    super()

    this.amount = cartTotal.value

    emitter.on('cart:updated', (total) => {
      this.resetCouponsAndPaymentMethods()
      this.amount = total
    })
  }

  get checkoutItemsCount() {
    return cartItemsFiltered.value?.length ?? 0
  }

  get isCouponsAllowed() {
    const { isPromotionCodeEnabled } = useProvider()

    return isPromotionCodeEnabled.value
  }

  get checkoutURL() {
    return '/v3/purchase-orders/checkout'
  }

  get isAddressSelectionAllowed() {
    return true
  }

  get burnPointsRate() {
    return burnPointsRateOrder.value
  }

  get pointsEarned() {
    return calculatePointsEarnedForPurchase(cartItemsFiltered.value)
  }

  get isOrderPoints() {
    return isPurchaseOrderPoints.value
  }

  get shippingFees() {
    return cartShippingFee.value
  }

  get cartAmount() {
    return (
      roundFigure(cartTotal.value) + this.transactionFees - this.payWithCoupons
    )
  }

  get hasPhysicalItems() {
    return cartHasPhysical.value
  }

  get hasEstoreItems() {
    return cartHasEstore.value
  }

  get hasDeliverableItems() {
    return cartHasDelivery.value
  }

  get selectedPaymentInfo() {
    const { ewalletLabels } = useCms()
    const items = [
      this.payWithCard ? this.selectedCard : null,
      this.payWithPoints ? this.selectedPoints : null,
      this.payWithEwallet ? this.selectedEwallet : null,
      this.payWithBankAccount ? this.selectedBankAccount : null,
    ].filter(Boolean)

    if (!items.length) {
      return {
        icon: 'v2/custom/credit-card-outline',
        iconSize: 24,
        title: 'Select or add payment method',
      }
    }

    let icon
    let text
    const titles = []

    for (const item of items) {
      switch (item?.type) {
        case PaymentMethodType.creditCard:
          titles.push(items.length !== 1 ? 'Credit Card' : item.name)
          text = `**** **** **** ${item.number.slice(-4)}`
          icon = `cards/${cardCode(item)}`
          break
        case PaymentMethodType.eWallet:
          titles.push(ewalletLabels.value.ewalletCash)
          text = `Balance ${formatDollar(ewalletBalance.value)}`
          icon = 'plain/ewallet'
          break
        case PaymentMethodType.points:
          titles.push('Points')
          text = `Points balance ${formatNumber(pointsBalance.value)}`
          icon = 'plain/award'
          break
        case PaymentMethodType.bankAccount:
          titles.push(items.length !== 1 ? 'Bank Account' : item.accountName)
          text = translate('bankAccount.details', {
            acc: `*** *** *${item.accountNumber.slice(-2)}`,
            bsb: item.bsb,
          })
          icon = 'v2/custom/bank'
          break
      }
    }

    return {
      icon: items.length !== 1 ? 'payment_methods' : icon,
      text: items.length !== 1 ? 'Split payment' : text,
      title: titles.join(' + '),
      iconClass:
        icon === 'symbion/token'
          ? 'text-primary'
          : items.length
          ? 'text-primary'
          : '',
    }
  }

  get canAddPaymentMethods() {
    return (
      isMethodAvailable(PaymentMethodType.creditCard, FlowType.purchase) ||
      isMethodAvailable(PaymentMethodType.bankAccount, FlowType.purchase)
    )
  }

  get rootRoute() {
    return {
      name: 'rewards',
    }
  }

  initPayment(payment: any) {
    super.initPayment(payment)

    this.amount = this.cartAmount
  }

  async preview() {
    return {} as PaymentOrderList
  }

  clearItems() {
    clearCart()
    resetCardOrderAddresses()
  }

  onPayFinished(_: unknown) {
    setTimeout(() => {
      emitter.emit('purchases-updated')
      emitter.emit('savings-updated')
      this.clearItems()
    }, 5000)
  }

  async getTransactionFees() {
    const { isMsfFeesPurchaseOrdersProviderFunded } = useProvider()

    if (isMsfFeesPurchaseOrdersProviderFunded.value) {
      this.feesSuccess = true
      this.transactionFees = 0
    } else {
      await super.getTransactionFees()
    }
  }

  async pay() {
    if (!this.checkoutURL) {
      throw new Error(
        `Property "checkoutURL" is not specified for the class ${this.constructor.name}`
      )
    }

    const { isBillPaymentsTemplate } = useProvider()
    const payload = this.getPayload()

    try {
      if (!isBillPaymentsTemplate.value) {
        await this.showBankAccountWarning()
      }

      const { data } = await api.post<ApiResponseData<Record<string, any>>>(
        this.checkoutURL,
        payload
      )

      this.onPayFinished(data)

      return data
    } catch (error: any) {
      if (error.message !== ERROR_PAYMENT_CANCELLED) {
        console.error(error.data?.errors?.[0] || error.data?.message || error)
      }

      /** Handle checkout error: product does not exist anymore */
      if (error?.data?.status === 410 && error?.data?.title === 'Gone') {
        emitter.emit('checkout:cart-items-gone', error?.data?.message)
      }

      throw error.data ?? error
    }
  }

  async checkoutByAccount() {
    this.submitting = true

    try {
      await this.payByAccount()
    } catch (error) {
      console.error('checkout', error)
      throw error
    } finally {
      this.submitting = false
    }
  }

  async payByAccount() {
    let response

    try {
      response = await api.post<ApiResponseData<CheckoutPayByAccountResponse>>(
        '/v3/purchase-orders/checkout/pay-by-account',
        {},
        {
          notify: false,
        }
      )
    } catch (error: any) {
      throw new Error(error?.data?.message || 'Failed to complete transaction')
    }

    if (!response.data?.paymentLinkUrl?.url) {
      throw new Error('There was an error initializing Pay by Account')
    }

    window.location.replace(response.data.paymentLinkUrl.url)
  }

  async getOrder(orderNumber: string) {
    this.confirmation.loading = true

    try {
      const { data } = await api.get<ApiResponseData<PurchaseOrder>>(
        `/v3/purchase-orders/${orderNumber}`
      )

      this.confirmation.order = data
    } catch (error) {
      console.error(error)
    } finally {
      this.confirmation.loading = false
    }
  }

  reset() {
    super.reset()

    this.amount = this.cartAmount
    this.isSchedulingAllowed = false
  }
}

export default PurchaseCheckout
