import {
  Address,
  ApiResponseData,
  PaymentOrderList,
  StatementOrderRawData,
} from '/~/types/api'
import api from '/~/core/api'
import ui from '/~/core/ui'
import {
  FlowType,
  SelectedPaymentMethodCreditCard,
} from '/~/composables/checkout/checkout-types'
import { ModalsConfig } from '/~/composables/payment-methods/use-card-verification'
import { usePoints } from '/~/composables/points'
import { Statement, useStatementAutoPayments } from '/~/composables/statements'
import PaymentCheckout from './PaymentCheckout'

const { burnPointsRateStatementOrder } = usePoints()

export type CardVerificationConfig = {
  modalsConfig?: ModalsConfig
}

export class StatementCheckout extends PaymentCheckout {
  flowType = FlowType.statement
  orderType = FlowType.statement
  statement: Statement | null = null
  isDirectDebit = false

  get checkoutURL() {
    return `/v3/statement-orders/${this.statement?.id}`
  }

  get isReadyForPayment() {
    return Boolean(this.statement)
  }

  get payeeId() {
    if (!this.statement?.payeeId && this.statement?.raw?.payee?.id)
      // TODO: check why the `payeeId` getter is not being initiated
      console.debug('Statement payeeId getter', this.statement)
    return (this.statement?.payeeId || this.statement?.raw?.payee?.id) ?? null
  }

  get burnPointsRate() {
    return burnPointsRateStatementOrder.value
  }

  async initPayment(payment: any) {
    super.initPayment(payment)

    const { hasAccountAutoPayment, isPayNow } = useStatementAutoPayments()

    this.isSchedulingAllowed = false
    this.isDirectDebit = false
    this.statement = payment.statement as Statement
    this.amount = this.statement.subtotal
    this.date = payment.date

    const isDirectDebit = await hasAccountAutoPayment(
      payment.statement?.statementAccount?.id
    )

    this.isSchedulingAllowed = !(isPayNow.value || (ui.mobile && isDirectDebit))

    this.isDirectDebit = isDirectDebit

    await this.getProgramFees()
  }

  reset() {
    super.reset()

    this.statement = null
  }

  onPayFinished(data: StatementOrderRawData) {
    this.statement = new Statement(data)
  }

  // Redefined parent Checkout class method
  getCardVerificationPayload({
    card,
    address,
    cardVerification,
  }: {
    card: SelectedPaymentMethodCreditCard
    address?: Address
    cardVerification?: CardVerificationConfig
  }) {
    return {
      card,
      amount: this.subTotal,
      subTotal: this.subTotal,
      address: address ?? this.address,
      multipleSources: this.multipleSourcesSelected,
      type: this.cardVerificationType,
      modalsConfig: cardVerification?.modalsConfig,
      payeeId: undefined as string | undefined,
    }
  }

  async preview() {
    return {} as PaymentOrderList
  }

  async getOrder(orderNumber: string) {
    this.statement = null
    this.confirmation.loading = true
    this.fetching = true

    try {
      const { data } = await api.get<ApiResponseData<StatementOrderRawData>>(
        `/v3/statement-orders/${orderNumber}`
      )

      const statement = new Statement(data)

      await this.initPayment({
        statement,
      })

      this.confirmation.order = data as any
      this.transactionFees = statement.transactionFees
      // TODO: investigate why it here? Statement doesn't have programFeesData field
      // this.programFeesData = statement.programFeesData
      this.programFees = statement.programFees

      return statement
    } catch (error) {
      console.error(error)
    } finally {
      this.confirmation.loading = false
      this.fetching = false
    }
  }
}

export default StatementCheckout
