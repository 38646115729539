import { computed, ref } from 'vue'
import { ApiResponseData } from '/~/types/api'
import { PaymentMethodBankAccount } from '/~/types/api/payment-methods'
import api from '/~/core/api'
import emitter from '/~/core/emitter'
import { useExtensions } from '/~/composables/extensions'
import { usePaymentMethods } from '/~/composables/payment-methods'

const { getConfigByName } = useExtensions()

type WithdrawBankAccount = Omit<PaymentMethodBankAccount, 'state'> & {
  config: Record<string, { enabled: boolean }>
}

const withdrawalBankAccounts = ref<WithdrawBankAccount[]>([])
const isBankAccountsLoading = ref(true)

const withdrawCashConfig = computed(() => {
  return getConfigByName('withdraw-cash')
})

async function getWithdrawalOrder(id: string) {
  try {
    const { data } = await api.get(
      `/v3/ewallets/withdrawal/${id}`
      // `/v3/withdraw-orders/${id}`
    )

    return data
  } catch (error: any) {
    throw new Error(error)
  }
}

async function getWithdrawalBankAccounts() {
  withdrawalBankAccounts.value = []
  isBankAccountsLoading.value = true

  try {
    const { data } = await api.get<ApiResponseData<WithdrawBankAccount[]>>(
      '/v3/payment-methods/withdrawal-bank-accounts'
    )

    withdrawalBankAccounts.value = data
  } catch (error: any) {
    throw new Error(error)
  } finally {
    isBankAccountsLoading.value = false
  }
}

async function withdrawCash(amount: number | string, description: string) {
  const { eWallets } = usePaymentMethods()

  const payload = {
    paymentSources: [
      {
        paymentMethodId: (eWallets.value[0] as any).id,
        amount: amount,
      },
    ],
    paymentDestinations: [
      {
        paymentMethodId: (withdrawalBankAccounts.value[0] as any).id,
        amount: amount,
      },
    ],
    reference: description,
  }

  const { data } = await api.post('/v3/ewallets/withdrawal', payload)

  return data
}

emitter.on('payment-methods:verified', (method) => {
  if (!method) return

  const bankAccount = withdrawalBankAccounts.value.find(
    (item) => item.id === method.id
  )

  if (!bankAccount) {
    return
  }

  bankAccount.status = method.status
})

export const useWithdraw = () => ({
  getWithdrawalBankAccounts,
  withdrawalBankAccounts,
  isBankAccountsLoading,
  withdrawCash,
  withdrawCashConfig,
  getWithdrawalOrder,
})
