<script setup lang="ts">
import { nanoid } from 'nanoid'
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import modal from '/~/core/mdl'
import ui from '/~/core/ui'
import MdlPopupV1 from '/~/components/mdl/mdl-popup.v1.vue'
import MdlPopupV2 from '/~/components/mdl/mdl-popup.v2.vue'
import { useUI } from '/~/composables/ui'

type TransitionType = 'slide-bottom' | 'slide-right'

export type ModalWidth =
  | 'xxs'
  | 'xs'
  | 'xse'
  | 'sm'
  | 'sme'
  | 'md'
  | 'lg'
  | 'xl'
  | 'screen'

const TRANSITIONS: {
  [K: string]: {
    [K: string]: string
  }
} = {
  'slide-bottom': {
    'enter-class': 'translate-y-full',
    'enter-active-class': 'translate-y-0',
    'leave-active-class': 'translate-y-0',
    'leave-to-class': 'translate-y-full',
  },
  'slide-right': {
    'enter-class': 'translate-x-full',
    'enter-active-class': 'translate-x-0',
    'leave-active-class': 'translate-x-0',
    'leave-to-class': 'translate-x-full',
  },
}

const props = withDefaults(
  defineProps<{
    layout?: false | 'v1' | 'v2'
    fullscreen?: 'mobile' | boolean
    closable?: boolean
    showCloseIcon?: boolean
    width?: ModalWidth
    height?: 'xs' | 'sm' | 'md' | 'lg'
    borderRadius?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'
    transparent?: boolean
    modalClass?: string
    modalStyles?: string
    transition?: TransitionType | string
  }>(),
  {
    layout: 'v1',
    fullscreen: false,
    closable: true,
    showCloseIcon: true,
    width: 'sm',
    borderRadius: 'lg',
    transparent: false,
  }
)

const keyboardAccessAreaId = nanoid()
const { isStandalone, lockKeyboardAccessArea, unlockKeyboardAccessArea } =
  useUI()

const rootElement = ref<HTMLDivElement>()
const currentModal = ref<typeof modal.active>()

function onReady() {
  currentModal.value = modal.active
  currentModal.value?.on.show?.()
}

function handleKeys(event: KeyboardEvent) {
  if (event.key === 'Escape') {
    close()
  }
}

onMounted(() => {
  window.addEventListener('keyup', handleKeys)
  lockKeyboardAccessArea({
    id: keyboardAccessAreaId,
    rootElement: rootElement.value,
    delay: 200,
  } as any)
})

onBeforeUnmount(() => {
  window.removeEventListener('keyup', handleKeys)
  unlockKeyboardAccessArea(keyboardAccessAreaId)
  currentModal.value?.on?.hide?.()
  currentModal.value = undefined
})

const layoutComponent = computed(() => {
  if (props.layout === 'v2') {
    return MdlPopupV2
  } else if (props.layout === 'v1') {
    return MdlPopupV1
  }

  return null
})

const isFullscreen = computed(() => {
  const fs = props.fullscreen

  return fs === true || (fs === 'mobile' && ui.mobile)
})

const borderRadiusClass = computed(() => {
  if (isFullscreen.value) {
    return ''
  }

  switch (props.borderRadius) {
    case 'xs':
      return 'rounded-xs'
    case 'sm':
      return 'rounded-sm'
    case 'md':
      return 'rounded-md'
    case 'xl':
      return 'rounded-xl'
    case '2xl':
      return 'rounded-2xl'
    case '3xl':
      return 'rounded-3xl'
  }

  return 'rounded-lg'
})

const transitionBindings = computed(() => {
  if (!isFullscreen.value) {
    return { name: 'slide-y' }
  }

  const bindings = TRANSITIONS[props.transition ?? '']

  return bindings || { name: props.transition }
})

function close() {
  if (props.closable) {
    modal.hide(currentModal.value?.name)
  }
}
</script>

<template>
  <transition
    v-bind="transitionBindings"
    :duration="100"
    appear
    @after-enter="onReady"
  >
    <div
      ref="rootElement"
      class="fixed z-1 flex h-full w-full transform flex-col overflow-y-auto transition duration-200 ease-out"
      :class="{
        'px-[15px]': !isFullscreen,
      }"
      @mousedown.self="close"
    >
      <div class="pointer-events-none min-h-[15px] flex-1" />
      <div
        class="inset-0 z-1 m-auto overflow-y-auto overflow-x-hidden shadow-lg"
        :class="[
          {
            'footer-extra': isFullscreen && isStandalone,
            'w-64': width === 'xxs',
            'w-xs': props.width === 'xs',
            'w-xse': props.width === 'xse',
            'w-sm': props.width === 'sm',
            'w-sme': props.width === 'sme',
            'w-md': props.width === 'md',
            'w-lg': props.width === 'lg',
            'w-xl': props.width === 'xl',
            'w-screen': props.width === 'screen',
            'h-xs': props.height === 'xs',
            'h-sm': props.height === 'sm',
            'h-md': props.height === 'md',
            'h-lg': props.height === 'lg',
            'shrink-0': !props.height,
            'bg-default': !props.transparent,
            'fixed flex h-full max-h-full w-full max-w-full flex-col':
              isFullscreen,
            'max-w-full': !isFullscreen,
          },
          borderRadiusClass,
          modalClass,
        ]"
        :style="modalStyles"
      >
        <component
          v-bind="$attrs"
          :is="layoutComponent"
          v-if="layoutComponent"
          :show-close-icon="showCloseIcon"
          :closable="closable"
          @hide="close"
        >
          <template v-for="(_, slotName) in $scopedSlots" :slot="slotName">
            <slot :name="slotName" />
          </template>
        </component>
        <slot v-else />
      </div>
      <div class="pointer-events-none min-h-[15px] flex-1" />
    </div>
  </transition>
</template>
